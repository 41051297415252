import React, { useContext } from "react";
import logoBlue from "src/static/jv_logo_blue.png";
import logoWhite from "src/static/jv_logo_white.png";

import Link from "gatsby-link";

import { NavItems, NavItem } from "./Navbar.style";

import NavLinks from "./NavLinks";
import ToggleSwitch from "src/components/common/ToggleSwitch";
import ThemeToggleContext from "../ThemeToggleContext";

const NavDesktop = () => {
  const { theme } = useContext(ThemeToggleContext);
  return (
    <>
      <Link href="/" smooth offset={-100} hashSpy={true} to="/">
        <img width="60px" src={theme === "dark" ? logoWhite : logoBlue} alt="Josue Villanueva" />
      </Link>
      <nav>
        <NavItems>
          <NavLinks NavItem={NavItem} />
          {/* <NavItem>
            <Link to="/blog">blog</Link>
          </NavItem> */}
          <NavItem>
            <ToggleSwitch />
          </NavItem>
        </NavItems>
      </nav>
    </>
  );
};

export default NavDesktop;
