import React from "react";
import styled from "styled-components";
import Wrapper from "src/components/common/Wrapper";
import logoWhite from "src/static/jv_logo_white.png";

const FooterWrapper = styled.footer`
  width: 100vw;
  padding: 0px;
  background: ${p => (p.theme.dark ? p.theme.secondaryColor : p.theme.gradient)};
  p {
    font-size: 0.75rem;
    line-height: 35px;
    color: white;
    float: right;
  }
`;

const Footer = () => (
  <FooterWrapper>
    <Wrapper>
      <img width="35px" src={logoWhite} alt="Josue Villanueva" />
      <link rel="stylesheet" type="text/css" href="//code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css" />
      <p>
        Made with <i class="icon ion-heart"></i> by JV
      </p>
    </Wrapper>
  </FooterWrapper>
);

export default Footer;
